import React from 'react';
import Slider from 'react-slick';
import ReviewCard from '../cards/ReviewCard';

const CarouselSettings = {
  dots: 'false',
  infinite: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 1,
  slidesPerRow: 3,
  slidesToScroll: 1,
  rows: 3,
  autoplay: false,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1,
        slidesPerRow: 2,
        slidesToScroll: 1,
        rows: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        rows: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        dots: false,
        arrows: false,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        slidesPerRow: 1,
        rows: 1,
      },
    },
    {
      breakpoint: 360,
      settings: {
        dots: false,
        arrows: false,
        slidesToShow: 1.15,
        slidesToScroll: 1,
        slidesPerRow: 1,
        rows: 1,
      },
    },
  ],
};
const TextTestimonials = ({ testimonials }) => {
  return (
    <div className='kwn-text-testimonials'>
      <div className='container'>
        <Slider className='slick-activation-wrapper course-activation-3 edu-slick-button' {...CarouselSettings}>
          {[...testimonials]?.map((item, i) => (
            <div key={i} className='testimonial-card'>
              {item?.link ? (
                <a href={item?.link} target='_blank'>
                  <ReviewCard item={item} />{' '}
                </a>
              ) : (
                <ReviewCard item={item} />
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TextTestimonials;
