import Plyr from 'plyr';
import React, { useEffect, useState } from 'react';
import 'plyr/dist/plyr.css';
import { getVideoTestimonials } from '../../services/otherServices';
import KwnVideoTestiomonials from './KwnVideoTestimonials';
import Image from 'next/image';

const VideoTestiomonials = () => {
    const [videos, setVideos] = useState([]);

    const fetchTestimonials = async () => {
        const _devTmIds = [59, 53, 51, 52];
        const _liveTmIds = [26, 22, 35, 23, 32, 24];

        const tmIds = process.env.NODE_ENV === 'development' ? _devTmIds : _liveTmIds;

        try {
            const { data } = await getVideoTestimonials({
                tmIds,
            });
            if (data.data && data.data.length) {
                let _videos = Array.from(data.data);

                if (process.env.NODE_ENV === 'development') {
                    _videos = _videos.sort((a, b) => _devTmIds.indexOf(a?.id) - _devTmIds.indexOf(b?.id));
                } else {
                    _videos = _videos.sort((a, b) => _liveTmIds.indexOf(a?.id) - _liveTmIds.indexOf(b?.id));
                }

                setVideos(_videos);
            }
        } catch (error) {
            console.log('Something went wrong ⚠️');
        }
    };

    useEffect(() => {
        fetchTestimonials();
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            Plyr.setup('.js-player', {
                displayDuration: true,
                captions: {
                    active: false,
                },
                resetOnEnd: true,
                autopause: true,
                controls: ['play-large', 'play', 'progress', 'settings', 'pip'],
                fullscreen: {
                    enabled: true,
                    fallback: true,
                },
            });
        }
    }, []);

    if (!videos.length) {
        return <></>;
    }

    return (
        <div
            className='kwn-testimonials home-vid-testimonials'
            style={{ backgroundColor: 'white !important', backgroundImage: 'none !important' }}
        >
            <div className='container'>
                <div className='section-title'>
                    <h3 className='title'>Success Stories of Our Learners Post Completion of Their Certification 🎉</h3>
                </div>
            </div>

            <KwnVideoTestiomonials videos={videos} />
            <div className='container'>
                <div className='text-center arrow-icon-container'>
                    <p className='m-0'>
                        <i>Explore Our Budget-Friendly Masterclasses</i>
                    </p>
                    <span>
                        <Image
                            src='/kaarwan/icons/animation_lmswcskw_small.gif'
                            alt='arrow-animate'
                            width={68}
                            height={68}
                            objectFit='contain'
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default VideoTestiomonials;
