import React, { useState } from 'react';
import VideoTestiomonials from '../../common/VideoTestiomonials';

const BASE_DIR = '/constant/homepage/what-set-us-apart';

const ITEMS = [
  {
    id: 1,
    text: 'Experience <b>Engaging and Collaborative</b> Learning Environments',
    icon: '',
  },
  {
    id: 2,
    text: 'Unlock Your Potential with Guidance from <b>Industry Mentors</b>',
    icon: '',
  },
  {
    id: 3,
    text: 'Enhance Your Credibility with Our <b>Industry Recognized Certificate</b>',
    icon: '',
  },
  {
    id: 4,
    text: 'Assistance for <b>Job Seekers</b> to Improve Skills and Secure Employment',
    icon: '',
  },
];

const WhatSetUsApart = ({ videos = true, classes = '', title }) => {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      document.getElementById('promo-video').addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    }
  }, []);

  const [activeCard, setActiveCard] = useState(0);

  return (
    <div className={`what-set-us-apart-section edu-section-gap kw-section-gap ${classes}`}>
      <div className='container'>
        <div className='section-title'>
          <span className='pre-title'>WHAT SETS US APART</span>
          <h3 className='title'> {title ? title : 'Find Out More About Learning Experience at Kaarwan'} </h3>
        </div>
        <div className='row d-flex align-items-center'>
          <div className='col-lg-6 p-0 col-md-5 col-12'>
            <div className='video-wrapper w-100 h-100 d-flex align-items-center'>
              <video
                src='/constant/homepage/what-set-us-apart/what-set-us-apart.mp4'
                className='w-100 h-100'
                autoPlay={true}
                loop={true}
                controls={false}
                muted={true}
                id='promo-video'
                disablePictureInPicture
              />
            </div>
          </div>
          <div className='col-lg-6 col-md-7 col-12'>
            <div className='row g-5'>
              {ITEMS.map((item, ind) => (
                <div className='col-12' key={item.id}>
                  <div
                    className={`content-card d-flex align-items-center ${activeCard === ind + 1 ? 'active-card' : ''}`}
                    onClick={() => setActiveCard(ind + 1)}
                  >
                    <div className='image-wrapper w-100 h-100'>
                      <img
                        src={`${BASE_DIR}/icon-${ind + 1}.png`}
                        alt='icon-1'
                        className='w-100 h-100'
                        draggable={false}
                      />
                    </div>
                    <div className='content'>
                      <p className='m-0' dangerouslySetInnerHTML={{ __html: item.text }}>
                        { }
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {videos ? <VideoTestiomonials /> : null}
    </div>
  );
};

export default WhatSetUsApart;