import Plyr from 'plyr';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'plyr/dist/plyr.css';
import { useInView } from 'react-intersection-observer';

const videoCarouselSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 1,
                slidesPerRow: 2,
                slidesToScroll: 1,
                rows: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                slidesPerRow: 1,
                rows: 1,
            },
        },
        {
            breakpoint: 500,
            settings: {
                arrows: false,
                slidesToShow: 1.2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 360,
            settings: {
                arrows: false,
                slidesToShow: 1.1,
                slidesToScroll: 1,
            },
        },
    ],
};

const KwnVideoTestimonials = ({ videos = [], title = '', preTitle = '', classes = '' }) => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            Plyr.setup('.js-player', {
                displayDuration: true,
                captions: {
                    active: false,
                },
                resetOnEnd: true,
                autopause: true,
                controls: ['play-large', 'play', 'progress', 'settings', 'pip'],
                fullscreen: {
                    enabled: true,
                    fallback: true,
                },
            });
        }
    }, []);

    if (!videos.length) {
        return <></>;
    }

    return (
        <div className={`kwn-testimonials home-vid-testimonials ${classes}`} style={{ backgroundColor: 'white !important', backgroundImage: 'none !important' }}>
            <div className='container'>
                {title ? <div className="section-title">
                    <span className="pre-title">
                        {preTitle}
                    </span>
                    <h3 className="title">
                        {title}
                    </h3>
                </div> : null}

                <div className='video-testimonials mt-5'>
                    <Slider {...videoCarouselSettings}>
                        {videos.sort((a, b) => a.sequence - b.sequence).map((item) => (
                            <LazyVideoItem key={item.id} video={item} />
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

const LazyVideoItem = ({ video }) => {
    const { ref, inView } = useInView({ triggerOnce: true });
    const [src, setSrc] = useState(null);

    useEffect(() => {
        if (inView && !src) {
            setSrc(`${process.env.NEXT_PUBLIC_MEDIA_URL}/${video.video}`);
        }
    }, [inView, src, video]);

    return (
        <div className='vid-plyr-container' ref={ref}>
            <div className='vid-plyr'>
                <video
                    data-poster={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${video.videoImg}`}
                    className='js-player'
                    disablePictureInPicture
                    controls
                >
                    {src && <source src={src} type='video/mp4' />}
                </video>
            </div>
            <h4 className='name'>{video.name}</h4>
            <span className='company'>{video.company}</span>
        </div>
    );
};

export default KwnVideoTestimonials;
