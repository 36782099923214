import React from 'react';
import { BiLogoFacebook, BiLogoLinkedin } from 'react-icons/bi';
import { RiTwitterXLine } from 'react-icons/ri';
import { getSocialMediaPlatform } from '../../utils';
import Image from 'next/image';

const SocialIcons = {
    Twitter: <RiTwitterXLine size={22} color='#1B1B1B' />,
    Facebook: <BiLogoFacebook size={26} color='#097EEB' />,
    LinkedIn: <BiLogoLinkedin size={26} color='#007BB6' />,
};

const ReviewCard = ({ item }) => {
    return (
        <div className='kwn-review-card-1'>
            <div className='profile-header d-flex align-items-center justify-content-between'>
                <div className='user-details d-flex align-items-center'>
                    <div className='img-wrapper rounded-circle overflow-hidden'>
                        <Image
                            src={item?.User?.image}
                            alt={item?.User?.image}
                            className='w-100 h-100'
                            layout='fill'
                            unoptimized={true}
                            loading='lazy'
                        />
                    </div>
                    <div className='user-info d-flex align-items-start flex-column'>
                        <span className='user-name'>{item?.User?.name}</span>
                        <span className='user-occupation'>{item?.User?.occupation}</span>
                    </div>
                </div>
                {item?.link ? (
                    <div className='social-icon'>
                        <span
                            className={`icon-wrapper ${
                                item?.link ? getSocialMediaPlatform(item?.link).toLowerCase() : ''
                            } d-flex align-items-center justify-content-center`}
                        >
                            {item?.link ? SocialIcons[`${getSocialMediaPlatform(item?.link)}`] : null}
                        </span>
                    </div>
                ) : null}
            </div>
            <div className='review-text'>
                <p className='m-0'>{item?.comments}</p>
            </div>
        </div>
    );
};

export default ReviewCard;
