import React from 'react';
import TextTestimonials from '../../common/TextTestimonials';
import reviews from './participants_reviews.json';
import Link from 'next/link';
import CONSTANTS from '../../../utils/constants';

const Testimonials = ({ showCTA = true, sectionTitle = false, classes = '' }) => {
  return (
    <div className={`homepage-testimonials facebook-testimonials ${classes}`}>
      {sectionTitle ? <div className="container">
        <div className='section-title'>

          <span className="pre-title">
            student testimonials
          </span>
          <h3 className="title">Don't take our word for it, see what our members are saying.</h3>
        </div>
      </div> : null}
      <div className='slider-style-1'>
        <TextTestimonials testimonials={reviews.reviews} />
      </div>
      {showCTA ? <Link href={CONSTANTS.KWN_FB_PAGE}>
        <a
          className={`btn-read-all-reviews kw-btn-orange ${reviews.reviews.length > 9 ? 'mt--70' : ''} `}
          target='_blank'
        >
          Read what they have to say <i className='icon-arrow-right-line-right'></i>
        </a>
      </Link> : null}
    </div>
  );
};

export default Testimonials;